@use '@on3/styles/mixins' as mix;

@include mix.homelayout;

.sidebarright {
  position: relative;
}

.sticky {
  position: sticky;
  top: 114px;

  @media only screen and (min-width: 1140px) {
    top: 140px;
  }
}

.middleFeeds {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
}
