@use '@on3/styles/variables' as var;

.promo {
  max-width: 1340px;
  margin: 15px;
  min-height: 111px;

  @media screen and (min-width: 961px) {
    min-height: 100px;
  }

  @media screen and (min-width: 1370px) {
    margin: 20px auto;
  }
}
