@use '@on3/styles/mixins' as mix;

@use '@on3/styles/variables' as var;

@media screen and (min-width: 1140px) {
  .feed {
    grid-area: middle;
  }
}

.ksrBanner {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1544aa;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 75px;
  background-image: url('https://on3static.com/static/ksr-header-mobile.png?v=2');

  @media screen and (min-width: 750px) {
    background-size: cover;
    background-image: url('https://on3static.com/static/ksr-header-desktop.png?v=2');
    min-height: 150px;
  }

  @media screen and (min-width: 1140px) {
    background-size: auto 100%;
    min-height: 200px;
  }
}

.logo {
  width: auto;
  height: 50px;

  @media screen and (min-width: 1140px) {
    height: 75px;
  }
}

.sidebarright {
  display: none;

  @media screen and (min-width: 961px) {
    display: block;
    position: sticky;
    top: 150px;
  }

  @media screen and (min-width: 961px) and (min-height: 800px) {
    top: 20px;
  }

  @media screen and (min-width: 1140px) {
    grid-area: right;
  }
}

.sidebarleft {
  display: none;

  @media screen and (min-width: var.$mid-width) {
    display: block;
    grid-area: left;
    position: sticky;
    top: 20px;
  }
}

.sticky1 {
  position: sticky;
  top: 140px;
  z-index: var.$zIndex-ads;
}

.sticky2 {
  position: sticky;
  top: 440px;
  z-index: var.$zIndex-ads;
}

.inner {
  height: 100%;

  @include mix.spaceY(var.$spacing-lg);
}
